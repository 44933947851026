.icon {
  cursor: pointer;
  color: #535353;
  font-size: 20px !important;
}
.headerCell {
  font-weight: 500;
  overflow: hidden!important;
  line-height: 14px!important;
  white-space: normal!important;
}
.icon:nth-child(1),
.icon:nth-child(2) {
  margin-right: 10px;
}
.photoIcon {
  color: #1D8145;
  font-size: 20px !important;
  cursor: pointer;
  margin-top: 3px !important;
}
.noPhotoIcon {
  color: #535353;
  font-size: 20px !important;
  cursor: pointer;
  margin-top: 3px !important;
}

.disabledLink {
  pointer-events: none;
}

.customCell {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
    width: 16px;
  }
  .flag {
    margin-right: 5px;
    width: 20px;
  }
}
