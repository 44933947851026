.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .spinnerWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.editor {
  display: flex;
  flex-direction: column;
  .editorLegend {
    color: #868686;
  }
}

.header {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.errorMessage {
  color: red;
  align-self: flex-end;
  margin-bottom: 4px;
}

.icon {
  font-size: 24px !important;
  color: #1D8145;
  margin-bottom: 5px;
  margin-left: 10px;
}
.icon:hover {
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .title {
    margin-bottom: 10px;
  }
}

.icon {
  font-size: 24px !important;
  color: #000000;
}
.icon:hover {
  cursor: pointer;
}
.btnSection {
  display: flex;
  flex-direction: row;
  margin-top: 95px;
  justify-content: space-between;
  .saveBtn {
    background-color: #90A4AE !important;
    height: 30px;
    position: initial;

    .btnText {
      margin-right: 10px;
      color: #ffffff;
      text-transform: initial !important;
      line-height: 0;
      font-size: 13px !important;
    }

    .icon {
      font-size: 18px !important;
      color: #ffffff;
    }
  }
  .saveBtn:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}
.required {
  color: red;
}
