.inputElement {
  display: flex;
  margin-top: 20px;

  .title {
    white-space: nowrap;
  }
}

.inputNoMargin {
  display: flex;
  margin-top: 10px;

  .title {
    white-space: nowrap;
  }
}

.disabledField {
  background-color: #ECEFF1 !important
}