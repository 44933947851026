.headerButtonsWrapper {
  position: absolute;
  top: 18px;
  right: 20px;

  svg {
    cursor: pointer;
  }
}

.btnGroup {
  display: flex;
  margin-top: 20px;
  justify-content: right;
  .exitBtn {
    height: 30px;
    width: 120px;
    position: initial;
    align-items: center;
    background-color: #DEA0A8 !important;
    .btnText {
      align-self: center;
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
  .submitBtn {
    height: 30px;
    margin-right: 20px;    
    position: initial;
    width: 120px;
    align-items: center;
    background-color: #90A4AE !important;
    .btnText {
      align-self: center;
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}

.searchGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  .hint {
    margin-right: 20px;
  }
}

.error {
  color: #FF0000;
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  height: 21px !important;
}