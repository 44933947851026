.navWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #535353;
  justify-content: space-between;
  padding: 0 20px;
  filter: drop-shadow(0px 4px 4px #000000);
  .icon {
    margin-left: 8px;
    height: 60px;
  }
  .navigation {
    display: flex;
    flex-direction: row;
    flex-grow: 3;
    justify-content: center;
    .dropdownMenu:hover {
      cursor: pointer;
      background-color: #4D5C61;
      border-radius: 5px;
    }
    .dropdownMenu {
      .dropdownMenuHeader {
        display: flex;
        align-self: center;
        color: #ffffff;
        padding: 8px;
        border: 1px solid transparent;
        white-space: nowrap;
        margin: 0 20px;
      }
      .isActive {
        display: flex;
        color: #ffffff;
        text-decoration: none;
        white-space: nowrap;
        padding: 8px;
        margin: 0 20px;
        border: 1px solid #90A4AE;
        border-radius: 5px;
        background-color: #4D5C61;
        .label {
          white-space: nowrap;
          font-size: 0.875rem;
          filter: drop-shadow(0px 4px 4px #000000);
          text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        }
      }
      .dropdownMenuItemsShow {
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-top: 9px;
        background-color: #535353;
        width: inherit;
      }
      .dropdownMenuItemsHidden {
        display: none;
      }
    }
    .isActive {
      display: flex;
      color: #ffffff;
      align-self: center;
      text-decoration: none;
      padding: 8px;
      border: 1px solid #90A4AE;
      border-radius: 5px;
      background-color: #4D5C61;
      white-space: nowrap;
      margin-bottom: 2px;
      .label {
        white-space: nowrap;
        font-size: 0.875rem;
        filter: drop-shadow(0px 4px 4px #000000);
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
      }
    }
    .notActive {
      display: flex;
      color: #ffffff;
      text-decoration: none;
      padding: 8px;
      border: 1px solid transparent;
      white-space: nowrap;
      align-items: center;
      margin-bottom: 2px;
      .label {
        white-space: nowrap;
        font-size: 0.875rem;
        filter: drop-shadow(0px 4px 4px transparent);
      }
    }
    .notActive:hover {
      cursor: pointer;
      background-color: #4D5C61;
      padding: 8px;
      border-radius: 5px;
      margin-bottom: 2px;
    }
  }
  .languages {
    display: flex;
    align-items: center;
    .language {
      color: #ffffff;
      padding: 0;
      min-width: 30px;
    }
  }
  .login {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .email {
      color: #ffffff;
    }
    .logoutIcon {
      color: #ffffff;
    }
  }
}
