.wrapper {
  display: flex;
  flex-direction: column;
  padding: 35px 30px;
  background-color: #ECEFF1;

  .header {
    font-size: 18px !important;
    color: #535353;
    font-weight: 700;
    margin-bottom: 60px;
  }

  .largeInputElement {
    display: flex;
    justify-content: space-between;
    width: 610px;
    align-items: center;
    margin-top: 10px;
    .title {
      //white-space: nowrap;
      margin-right: 20px;
      line-height: 15px;
    }
  }
}
