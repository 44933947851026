.headerButtonsWrapper {
  position: absolute;
  top: 18px;
  right: 20px;

  svg {
    cursor: pointer;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  color: #535353;
  margin-bottom: 30px;
}

.spinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.form {
  display: grid;
  align-items: center;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;

 * {
   max-width: 300px;
 }

  li, div {
    font-size: 13px !important;
  }

  ul {
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  }

  .tooltipWrapper {
    display: grid;
    min-width: 330px !important;
    grid-template-columns: 1fr min-content;
    align-items: center;

    & > div {
      margin-top: 0 !important;
    }
  }
}

.error {
  color: #FF0000;
  display: flex;
  height: 21px !important;
  margin-top: 30px;
}

.helper {
  color: #666666;
}

.warnhelper {
  color: rgb(212, 114, 1);
}

.buttonWrapper {
  max-width: 100% !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 30px;

  .btn {
    text-decoration: none;
    text-transform: none;
    background-color: #90A4AE !important;
    color: #ffffff;
    font-size: 13px;
  }
}

