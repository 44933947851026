.wrapper {
  padding: 35px 30px 15px 30px;;
  background-color: #ECEFF1;
  display: flex;
  flex-direction: column;
  .loadingGroup {
    display: flex;
    flex-direction: row;
    .title {
      font-size: 16px !important;
      color: #535353;
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }

  .filterBlock {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .firstRow {
      display: flex;
      flex-direction: row;
      .calendarItem {
        display: flex;
        flex-direction: column;
        margin-right: 20px !important;
        align-self: self-end;

        .calendarRow {
          display: flex;
          align-items: center;

          img {
            cursor: pointer;
            height: 24px;
            width: 24px;
            margin: 0 10px;
          }
        }
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
      transition: background-color 9999s ease-in-out 0s;
      }

      .btn {
        height: 30px;
        background-color: #90A4AE !important;
        position: initial;
        align-self: end;
        margin-bottom: 1px;
        .btnText {
          font-size: 13px !important;
          text-transform: initial !important;
        }
      }
    }
    .secondRow {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      align-items: self-end;

      & > div:not(:first-child) {
        margin-right: 20px;
      }

      .searchGroup {
        display: flex;
        flex-direction: column;
      }
      .downloadWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .downloadIcon {
          font-size: 28px;
          color: #535353;
          //height: 30px;
          //width: 30px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
