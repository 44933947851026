.wrapper {
  padding: 35px 30px;
  height: 100%;
  background-color: #ECEFF1;
  .codesHeader {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: row;
    }

    .bankCodesTitle {
      font-size: 18px !important;
      color: #535353;
      font-weight: 700;
      margin-bottom: 40px;
    }
    .bankCodesSubTitle {
      font-size: 16px !important;
      color: #535353;
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }
  .codesForm {
    margin-top: 40px;
    margin-left: 15px;
    width: 700px;
  }
  .importFileSection {
    margin-left: 15px;
    margin-top: 60px;
    .btnGroup {
      display: flex;
      margin-top: 20px;
      .btn {
        background-color: #90A4AE !important;
        height: 30px;
        position: initial;
        .btnTex {
          font-size: 13px !important;
          text-transform: initial !important;
        }
        .icon {
          font-size: 18px !important;
        }
      }
      .btn:nth-child(1) {
        margin-right: 20px;
        position: initial;
      }
    }
  }
  .timerSection {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-top: 20px;
    .timer {
      margin-right: 50px;
    }
    .failedResponse {
      color: #C10606;
    }
  }
  .validateSuccessSection {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    .validateSuccessSection_Title {
      margin-bottom: 5px;
    }
    .validateSuccessSection_Body {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
    span {
      margin-top: 10px;
    }
  }
  .applySection {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
      span {
      margin-top: 20px;
    }
    .applySectionError {
      display: flex;
      flex-direction: row;
    }
    .failed {
      color: #C10606;
    }
  }

  .generateSection {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 30px;

    span {
      margin-top: 14px;
    }
    .failed {
      color: #C10606;
    }
  }
}

@media (min-width: 1800px) {
  .importFileSection {
    margin-left: 15px;
    margin-top: 20px;
    .btnGroup {
      display: flex;
      margin-top: 20px;
        .btn {
        background-color: #90A4AE !important;
        height: auto;
        position: initial;
        width: 160px;
        .btnTex {
          font-size: 13px !important;
          text-transform: initial !important;
        }
        .icon {
          font-size: 18px !important;
        }
      }
      .btn:nth-child(1) {
        margin-right: 30px;
        position: initial;
        width: 160px;
      }
    }
  }
}

