.wrapper {
  padding: 35px 30px 15px 30px;
  background-color: #ECEFF1;
  display: flex;
  flex-direction: column;

  .headerGroup {
    display: flex;
    flex-direction: row;
    .subtitle {
      font-size: 16px !important;
      color: #535353;
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }


  .searchSection {
    display: flex;
    margin-top: 40px;
    .searchGroup {
      display: flex;
      flex-direction: column;

      .hint {
        color: #535353;
        margin-top: 5px;
      }
    }
  }

  .btn {
    background-color: #90A4AE !important;
    height: 30px;
    position: initial;
    align-items: center;
    display: flex;
    align-self: self-end;
    .btnText {
      margin-right: 20px;
      margin-left: 20px;
      align-self: center;
      font-size: 13px !important;
      text-transform: initial !important;
    }
    .addIcon {
      font-size: 18px !important;
    }
  }
}

.icon {
  cursor: pointer;
  color: #535353;
  font-size: 20px !important;
}
