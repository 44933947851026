.wrapper {
  padding: 35px 30px 15px 30px;
  background-color: #ECEFF1;
  .templateHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .loadingGroup {
      display: flex;
      flex-direction: row;
      .templateSubTitle {
        font-size: 16px !important;
        color: #535353;
        font-weight: 700;
        margin-bottom: 5px;
        margin-right: 20px;
      }
    }
  }
  .header {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .searchSection {
      display: flex;
      .searchGroup {
        display: flex;
        flex-direction: column;
        .hint {
          color: #535353;
          margin-top: 5px;
        }
      }
      .btn {
        background-color: #90A4AE !important;
        position: initial;
        display: flex;
        align-self: self-end;
        height: 30px !important;
        .btnText {
          margin-right: 10px;
          font-size: 13px !important;
          text-transform: initial !important;
        }
        .icon {
          font-size: 18px !important;
        }
      }
    }
  }
}


@media (min-width: 1800px) {
  .wrapper {
    .btn {
      background-color: #90A4AE !important;
      position: initial;

      .btnText {
        margin-right: 10px;
      }
      .icon {
        font-size: 20px !important;
      }
    }
  }
}
