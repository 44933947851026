.wrapper {
  padding: 35px 30px 15px 30px;
  background-color: #ECEFF1;

  .block {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .searchBlock {
      display: flex;
      flex-direction: column;
      margin-bottom: 1px !important;
      margin-top: 10px;
    }
  }

  .codesHeader {
    display: flex;
    flex-direction: column;

    .loadingGroup {
      display: flex;
      flex-direction: row;
      .bankCodesSubTitle {
        font-size: 16px !important;
        color: #535353;
        font-weight: 700;
        margin-bottom: 5px;
        margin-right: 20px;
      }
    }
  }


  .searchSection {
    display: flex;
    margin-top: 40px;
    .btn {
      background-color: #90A4AE !important;
      margin-right: 20px;
      height: 30px;
      position: initial;
      .btnText {
        margin-right: 10px;
        font-size: 13px !important;
        text-transform: initial !important;
      }
      .icon {
        font-size: 18px !important;
      }
    }
  }
}
