.buttonsWrapper {
  position: absolute;
  top: 18px;
  right: 20px;

  svg {
    cursor: pointer;
  }
}

.responseText {
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 20px;
  color: #C10606;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.title {
  display: block;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  color: #535353;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.spinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.innerModalSpinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.mainButtonWrapper {
  display: flex;
  margin-top: 30px;
  margin-left: 75px;

  & > button:first-child {
    margin-right: 20px;
  }

  .btn {
    background-color: #90A4AE !important;
    height: 37px !important;
    display: flex !important;
    width: 290px !important;
    .btnText {
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}

.btnGroupInner {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;

  .submitBtn {
    background-color: #90A4AE !important;
    height: 37px !important;
    display: flex !important;
    width: 110px !important;
    .btnText {
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }

  .exitBtn {
    background-color: #90A4AE !important;
    height: 37px !important;
    display: flex !important;
    width: 110px !important;
    font-size: 13px !important;
    text-transform: initial !important;
    .btnText {
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}

.btn {
  background-color: #90A4AE !important;
  height: 37px !important;
  display: flex !important;
  width: 100% !important;
  font-size: 13px !important;
  text-transform: initial !important;
  .btnText {
    font-size: 13px !important;
    text-transform: initial !important;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}