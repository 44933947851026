.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btnGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  .btn {
    text-decoration: none;
    background-color: #90A4AE !important;
    height: 30px;
  }
  .btn:nth-child(1) {
    margin-right: 80px;
    text-decoration: none;
    background-color: #90A4AE !important;
    height: 30px;
  }
}
