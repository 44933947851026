.wrapper {
  padding: 35px 30px;
  background-color: #ECEFF1;
  display: flex;
  flex-direction: column;

  .documentGroup {
    display: flex;
    flex-direction: row;
    .subtitle {
      font-size: 16px !important;
      color: #535353;
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }

  .sectionsWrapper {
    display: flex;
    flex-direction: column;
    .tools {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;
      .calendarBlock {
        display: flex;
        .calendarItem {
          display: flex;
          flex-direction: column;
          margin-right: 20px !important;
          align-self: self-end;
        }
        .btn {
          height: 30px;
          background-color: #90A4AE !important;
          position: initial;
          align-self: flex-end;
          margin-bottom: 1px;
          .btnText {
            font-size: 13px !important;
            text-transform: initial !important;
          }
        }
      }
      .searchBlock {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 1px !important;
        margin-left: 20px !important;
        .btn {
          height: 30px;
          background-color: #90A4AE !important;
          position: initial;
          align-self: flex-end;
          .btnText {
            font-size: 13px !important;
            text-transform: initial !important;
          }
          .icon {
            font-size: 16px !important;
            margin-left: 10px;
          }
        }
      }
    }
    .btn {
      background-color: #90A4AE !important;
      height: 30px;
      position: initial;
      align-self: self-end;
      margin-bottom: 1px;
      .addBtnText {
        margin-right: 10px;
        font-size: 13px;
        text-transform: initial !important;
        color: white;
        text-decoration: none !important;
      }
      .icon {
        font-size: 18px !important;
        color: white;
      }
    }
  }

  .searchSection {
    display: flex;
    margin-top: 10px;
    .searchGroup {
      display: flex;
      flex-direction: column;

      .hint {
        color: #535353;
        margin-top: 5px;
      }
    }
  }
}
