.wrapper {
  display: flex;
  flex-direction: column;
  padding: 35px 30px;
  background-color: #ECEFF1;

  .loadingGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .title {
      font-size: 18px !important;
      color: #535353;
      font-weight: 700;
      margin-right: 20px;
    }

    .refreshIcon {
      cursor: pointer;
    }
  }

  .subtitle {
    margin-top: 5px;
  }

  .main {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .queueTitle {
      margin-bottom: 20px;
      font-weight: 500;
    }

    .dataTable {
      display: grid;
      grid-template-columns: min-content min-content;
      grid-column-gap: 15px;
      
      .column {
        display: grid;
        grid-template-columns: 1fr;
        
        span {
          white-space: nowrap;
          margin-bottom: 5px;
        }
      }
    }
  }

  .conveyors {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .conveyorsTitle {
      font-weight: 500;
      margin-bottom: 15px;
    }

    .conveyorsGrid {
      display: grid;
      grid-template-columns: max-content min-content;

      .conveyorsData {
        display: grid;
        grid-template-columns: min-content min-content;
        grid-column-gap: 15px;
        margin-top: 35px;

        .conveyorsDataColumn {
          display: grid;
          grid-template-columns: 1fr;
          height: 40px;

          span {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .res {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .resTitle {
      font-weight: 500;
    }
    .resBody {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      .column {
        display: flex;
        flex-direction: column;
        width: 255px;
        margin-right: 20px;
        .btn {
          height: 30px;
          background-color: #90A4AE !important;
          position: initial;
          align-self: start;
          width: 140px;
          .btnText {
            font-size: 13px !important;
            text-transform: initial !important;
          }
        }
        .columnItem {
          margin-bottom: 5px;
          height: 20px;
          .downloadIcon {
            font-size: 24px;
            //height: 30px;
            //width: 30px;
            cursor: pointer;
            margin-left: -5px;
          }
        }
      }
    }
  }
}
