.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 40px;

  .title {
    color: #1D8145;
    margin-bottom: 5px;
  }

  .list {
    margin-top: 20px;
    padding-left: 15px;
    margin-bottom: 30px;

    .li:not(:last-child) {
      margin-top: 6px;
    }
  }

  .submitWrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;

    .submitText {
      margin-bottom: 10px;
    }

    .btn {
      background-color: #90A4AE !important;
      height: 37px;
      display: flex;
      width: 100%;

      .btnText {
        font-size: 13px !important;
        color: #ffffff;
        text-transform: initial !important;
      }
    }
  }
}