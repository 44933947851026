.inputElement {
  display: flex;
  justify-content: space-between;
  width: 600px;
  align-items: center;
  margin-top: 10px;
  .title {
    white-space: nowrap;
    line-height: 15px;
  }
}
.required {
  color: #FF0000;
}
.smallInputElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin-top: 10px;
  .title {
    //white-space: nowrap;
    margin-right: 20px;
    line-height: 15px;
  }
}

.largeInputElement {
  display: flex;
  justify-content: space-between;
  width: 610px;
  align-items: center;
  margin-top: 10px;
  .title {
    //white-space: nowrap;
    margin-right: 20px;
    line-height: 15px;
  }
}
