.wrapper {
  padding: 35px 30px;
  background-color: #ECEFF1;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    align-items: center;
    font-size: 18px !important;
    color: #535353;
    font-weight: 700;

    span{
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .table {
    .itemsRow {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      padding: 0 50px 0 50px;
      white-space: nowrap;
      .mockedItem {
        display: flex;
        flex-direction: column;
        width: 250px;
        white-space: nowrap;
      }
      .item {
        display: flex;
        flex-direction: column;
        width: 250px;
        white-space: nowrap;
        .link {
          white-space: nowrap;
          font-size: 13px !important;
          font-weight: 500;
          color: #000000;
          text-decoration: none;
        }
        .link:nth-child(3),
        .link:nth-child(4),
        .link:nth-child(5),
        .link:nth-child(6),
        .link:nth-child(7) {
          margin-top: 10px;
        }
        .itemHeader {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color:  #535353;
          border-radius: 5px 5px 0 0;
          color: #ffffff;
          font-size: 13px !important;
          font-weight: 500;
          margin-bottom: 20px;
          white-space: nowrap;
        }
        .itemBody {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          .itemIcon {
            font-size: 16px !important;
            margin-right: 10px;
          }
          .itemText {
            font-size: 13px !important;
            font-weight: 500;
            white-space: nowrap;
          }
        }
        .itemBody:hover {
          cursor: pointer !important;
          white-space: nowrap;
        }
        .itemBody:nth-child(3),
        .itemBody:nth-child(4),
        .itemBody:nth-child(5),
        .itemBody:nth-child(6),
        .itemBody:nth-child(7),
        .itemBody:nth-child(8),
        .itemBody:nth-child(9),
        .itemBody:nth-child(10) {
          margin-top: 10px;
          white-space: nowrap;
        }
      }
      .item:nth-child(1),
      .item:nth-child(2) {
        margin-right: 150px;
        white-space: nowrap;
      }
    }
    .itemsRow:nth-child(1) {
      margin-top: 50px;
      white-space: nowrap;
    }
    .itemsRow:nth-child(2) {
      margin-top: 100px;
      white-space: nowrap;
      .item:nth-child(1),
      .item:nth-child(2) {
        margin-right: 150px;
        white-space: nowrap;
      }
    }
  }
}
