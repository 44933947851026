.wrapper {
  top: 0 !important;
  right: calc(50%) !important;
  position: fixed !important;
  transform: translateX(50%) !important;
  z-index: 9999 !important;
  font-size: 13px !important;
  color: black !important;

  & > div {
    font-size: 1rem !important;
  }

  .errorBody {
    background-color: orange !important;
    font-size: 13px !important;
    width: 400px;
    -webkit-box-shadow: -1px 4px 28px 3px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: -1px 4px 28px 3px rgba(0, 0, 0, 0.2) !important;
    box-shadow: -1px 4px 28px 3px rgba(0, 0, 0, 0.2) !important;
    .toastHeader {
      font-size: 13px !important;
      color: black !important;
      display: flex !important;
      justify-content: space-between !important;
    }
  }
}
