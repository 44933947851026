.headerButtonsWrapper {
  position: absolute;
  top: 18px;
  right: 20px;

  svg {
    cursor: pointer;
  }
}

.spinnerWrapper {
  position: absolute;
  top: 18px;
  left: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #000000;
  margin-bottom: 30px;
  margin-top: 10px;
}

.modalText {
  display: flex;
  flex-direction: row;
  justify-content: left;
  color: #000000;
  margin-bottom: 30px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: -50px;

  .selectWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .buttonWrapper {
    display: flex;
    justify-content: end;
    margin-top: 20px;

    .btn {
      background-color: #90A4AE !important;
      height: 30px;
      display: flex;
      width: 120px;
      margin-left: auto;
      .btnText {
        font-size: 13px !important;
        text-transform: initial !important;
      }
    }
  }

  .responseWrapper {
    margin-top: 10px;
  }


  .errorResponse {
    color: #FF0000;
  }
}
