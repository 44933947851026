.wrapper {
  display: flex;
  flex-direction: column;
  padding: 35px 30px 30px 30px;
  background-color: #ECEFF1;

  .loadingGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    .title {
      font-size: 18px !important;
      color: #535353;
      font-weight: 700;
      margin-right: 20px;
    }
  }

  .main {
    display: flex;
    margin-top: 20px;
    .form {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        .column {
          display: flex;
          flex-direction: column;
          margin-right: 20px;

          .fieldTitle {
            font-weight: 500;
          }
        }
      }
    }
  }
  .searchSection {
    display: flex;
    margin-top: 40px;
    .searchGroup {
      display: flex;
      flex-direction: column;

      .hint {
        color: #535353;
        margin-top: 5px;
      }
    }
  }
}