.headerButtonsWrapper {
  position: absolute;
  top: 18px;
  right: 20px;

  svg {
    cursor: pointer;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #535353;
  margin-bottom: 30px;
}

.spinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btn {
  background-color: #90A4AE !important;
  height: 37px !important;
  display: flex !important;
  width: 100% !important;
  font-size: 13px !important;
  text-transform: initial !important;
  .btnText {
    font-size: 13px !important;
    text-transform: initial !important;
    color: #ffffff !important;
  }
}

.group {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.errRes {
  height: 18px;
  display: flex;
  color: red;
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: nowrap;
}
.errResMock {
  height: 18px;
  margin-bottom: 20px;
}

.btnGroup {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 30px;
  .applyBtn {
    text-decoration: none;
    text-transform: none;
    background-color: #90A4AE !important;
    height: 30px;
    font-size: 13px;
    margin-right: 20px;
  }
    .exitBtn {
    text-decoration: none;
    text-transform: none;
    background-color: #DEA0A8 !important;
    height: 30px;
    font-size: 13px;
  }
  .btn:nth-child(1) {
    margin-right: 20px;
    text-decoration: none;
    text-transform: none;
    background-color: #90A4AE !important;
    height: 30px;
  }
}
