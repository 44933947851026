.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .icon {
    font-size: 24px !important;
    color: #000000;
    margin-bottom: 5px
  }
  .icon:hover {
    cursor: pointer;
  }
}
.btnGroup {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  justify-content: center;
  .deleteBtn {
    background-color: #DEA0A8 !important;
    height: 30px;
    position: initial;
    .btnText {
      margin-right: 10px;
      font-size: 13px !important;
      text-transform: initial !important;
      line-height: 0;
    }
    .icon {
      font-size: 18px !important;
    }
  }
  .btn {
    background-color: #90A4AE !important;
    height: 30px;
    position: initial;
    .btnText {
      margin-right: 10px;
      font-size: 13px !important;
      text-transform: initial !important;
      line-height: 0;
    }
    .icon {
      font-size: 18px !important;
    }
  }
  .btn:nth-child(3) {
    margin-right: 40px;
  }

  label {
    margin-right: 40px;
  }
}
.photoPlaceholder {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
}
