.wrapper {
  padding: 35px 30px;
  background-color: #ECEFF1;

  .header {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: row;
    }

    .title {
      font-size: 16px !important;
      color: #535353;
      font-weight: 700;
      margin-right: 20px;
    }
  }
  .filter {
    display: flex;
    margin-top: 40px;

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      .title {
        font-weight: 500;
      }
    }
    .btn {
      display: flex;
      background-color: #90A4AE !important;
      height: 30px;
      position: initial;
      align-self: end;
      .btnText {
        font-size: 13px !important;
        text-transform: initial !important;
      }
      .icon {
        font-size: 18px !important;
      }
    }
  }
  .contentWrapper {
    display: flex;
    .tree {
      width: 100%;
      margin-top: 40px;
    }
    .tree10 {
      margin-left: 50px;
      height: 123px;
      width: 100%;
    }
    .error {
      margin-top: 40px;
      color: #FF0000;
      display: flex;
      height: 21px;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .reportItem {
      display: flex;
      margin-bottom: 50px;
      .reportItemSection {
        display: flex;
        margin-right: 10px;
        width: 50px;
        .img {
          height: 24px;
        }
      }
      .reportItemDataWrap {
        display: flex;
        flex-direction: column;
        .reportItemData {
          display: flex;
          flex-direction: row;
          .reportItemDataKey {
            min-width: 270px;
            margin-right: 10px;
          }
        }
      }

      .photoWrapper {
        margin-left: 50px;
        display: flex;

        .iconWrapper {
          display: flex;
          flex-direction: column;
          margin-left: 15px;

          svg {
            cursor: pointer;
          }

          svg:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.controls {
  display: none;
}
