.inputElement {
  display: flex;
  margin-top: 20px;

  .title {
    white-space: nowrap;
  }
}

.inputNoMargin {
  display: flex;
  margin-top: 10px;
  height: 31px;

  .title {
    white-space: nowrap;
  }
}

.disabledField {
 background-color: #ECEFF1;
 height: 31px;
}
