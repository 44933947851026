.buttonsWrapper {
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    cursor: pointer;
  }

  & > svg:first-child {
    margin-right: 10px;
  }
}

.spinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.table {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 40px;
  width: 425px;
  margin-top: 40px;
  margin-left: 80px;
  text-wrap: nowrap;

  .firstColumn {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 4px;
  
    span {
      align-self: center;
    }

    svg {
      cursor: pointer;
    }
  }

  .column {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 4px;

    span {
      align-self: center;
    }
  }
}
