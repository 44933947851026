.wrapper {
  padding: 35px 30px;
  background-color: #ECEFF1;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px !important;
    color: #535353;
    font-weight: 700;
  }
}
