.wrapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  .staticBox {
    display: flex;
    flex-direction: column;
  }
  .staticBox:first-child {
    margin-right: 76px;
  }
}
.error {
  color: #FF0000;
  display: flex;
  height: 21px !important;
}
.searchGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  .hint {
    margin-right: 20px;
  }
}

.btnGroup {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  .exitBtn {
    height: 30px;
    width: 120px;
    margin-right: 20px;
    position: initial;
    align-items: center;
    background-color: #DEA0A8 !important;
    .btnText {
      align-self: center;
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
  .submitBtn {
    height: 30px;
    position: initial;
    width: 120px;
    align-items: center;
    background-color: #90A4AE !important;
    .btnText {
      align-self: center;
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}
