.buttonsWrapper {
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    cursor: pointer;
  }

  & > svg:first-child {
    margin-right: 10px;
  }
}

.responseText {
  display: flex;
  align-items: center;
  height: 24px;
  position: absolute;
  top: 20px;
  left: 40px;
  color: #C10606;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}

.spinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.innerModalSpinnerWrapper {
  position: absolute;
  top: 20px;
  left: 20px;
}

.title {
  display: block;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: center;
  color: #535353;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.table {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 60px;
  width: 425px;
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 80px;
  text-wrap: nowrap;

  .firstColumn {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: max-content;
    row-gap: 4px;

    span {
      align-self: center;
      text-align: left;
    }

    svg {
      cursor: pointer;
      color: gray;
    }
  }

  .column {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 4px;

    span {
      align-self: center;
    }
  }
}

.mainButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .btn {
    background-color: #90A4AE !important;
    height: 37px !important;
    display: flex !important;
    width: 100% !important;
    .btnText {
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}

.btnGroupInner {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;

  .submitBtn {
    background-color: #90A4AE !important;
    height: 37px !important;
    display: flex !important;
    width: 110px !important;
    .btnText {
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }

  .exitBtn {
    background-color: #90A4AE !important;
    height: 37px !important;
    display: flex !important;
    width: 110px !important;
    .btnText {
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}
