.dropdownElement {
  display: flex;
  justify-content: space-between;
  width: 600px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.noLabelDropdownElement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  height: 31px;
}
