.wrapper {
  padding: 70px 30px;
  background-color: #ECEFF1;

  .loadingGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .pageTitle {
      display: flex;
      justify-content: center;
      color: #535353;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }

  .closeGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .iconClose {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .body {
    padding: 50px 30px;
    background-color: #FFFFFF !important;

    .header {
      display: flex;
      flex-direction: row;

      .column {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .title {
          font-weight: 500;
        }
      }

      .calendarItem {
        display: flex;
        flex-direction: column;
        margin-right: 20px !important;
        align-self: self-end;

        .title {
          font-weight: 500;
        }
      }
    }

    .error {
      color: #FF0000;
      display: flex;
      height: 21px;
    }

    .dividerText {
      display: flex;
      justify-content: center;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .mainDivider {
      margin-top: 30px;
      margin-bottom: 20px;
      border-color: rgb(9, 9, 9) !important;
    }

    .checkboxesWrapper {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .checkboxField {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .checkboxField:first-child {
        margin-bottom: 10px;
      }
    }

    .additionalBlock {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;

      .filterProduct {
        display: flex;
        flex-direction: column;

        .title {
          font-weight: 700;
          margin-bottom: 20px;
        }

        .searchSection {
          display: flex;

          .searchGroup {
            display: flex;
            flex-direction: column;

            .hint {
              color: #535353;
              font-size: 12px;
              font-weight: 300;
              margin-top: 5px;
            }
          }

          .AddProductBtn {
            background-color: #90A4AE !important;
            height: 30px;
            position: initial;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;

            .btnText {
              margin-right: 10px;
              align-self: center;
              font-size: 13px !important;
              text-transform: initial !important;
            }

            .icon {
              font-size: 18px !important;
            }

          }

          .btn:nth-child(2) {
            margin-right: 20px;
            margin-left: 20px;
            position: initial;
          }
        }
      }

      .downloadSection {
        display: flex;
        flex-direction: column;
        .downloadWrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .downloadIcon {
            font-size: 24px;
            //height: 30px;
            //width: 30px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .downloadWrapper:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .divider {
      margin-top: 40px;
      margin-bottom: 20px;
      border-color: rgb(9, 9, 9) !important;
    }

    .buttonGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      .deleteBtn {
        background-color: #DEA0A8 !important;
        height: 30px;

        .deleteBtnText {
          margin-right: 10px;
          font-size: 13px !important;
          text-transform: initial !important;
        }

        .icon {
          font-size: 18px !important;
        }
      }
      .addSeriesBtn {
        background-color: #90A4AE !important;
        height: 30px;
        position: initial;
        align-items: center;
        width: 160px;
        margin-right: 30px;

        .btnText {
          margin-right: 10px;
          align-self: center;
          font-size: 13px !important;
          text-transform: initial !important;
        }

        .icon {
          font-size: 18px !important;
        }
      }
    }

    .margin {
      margin-top: 20px;
    }
    .margin:first-child {
      margin-top: 50px;
    }
    .main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #ECEFF1;
      padding: 5px 15px 5px 5px;
      .deleteProductIcon {
        color: #FF0000;
        cursor: pointer;
      }
      .mainHeader {
        display: flex;
        flex-direction: row;
        .mainHeaderIndex {
          display: flex;
          margin-right: 5px;
          font-weight: 500;
        }
        .mainHeaderData {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          margin-right: 20px;
        }


        .gtinNum {

        }

        .internalName {
          font-weight: 500;
        }
      }

      .searchSection {
        display: flex;

        .searchGroup {
          display: flex;
          flex-direction: column;

          .hint {
            color: #535353;
            margin-top: 5px;
          }
        }

        .btn {
          background-color: #90A4AE !important;
          height: 30px;
          position: initial;
          align-items: center;
          width: 110px;

          .btnText {
            margin-right: 10px;
            align-self: center;
            font-size: 13px !important;
            text-transform: initial !important;
          }

          .icon {
            font-size: 18px !important;
          }
        }

        .btn:nth-child(2) {
          margin-right: 20px;
          margin-left: 20px;
          position: initial;
        }
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 40px;

      .tableHeader {
        display: flex;

        th {
          margin-right: 33px;
          font-weight: 400;
        }

        th:first-child {
          display: flex;
          align-items: center;
          width: 300px !important;
          margin-right: 67px !important
        }

        th:nth-child(2) {
          width: 100px !important;
          margin-right: 38px !important;
          line-height: 15px;
        }

        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(5),
        th:nth-child(6) {
          width: 100px !important;
          line-height: 15px;
        }
      }

      .tableBody {
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          flex-direction: row;
          width: 100%;

          td {
            display: flex;
            margin-right: 32px;
          }

          td:first-child {
            display: flex;
            margin-right: 64px;
          }

          td:nth-child(2) {
            margin-right: 26px;
          }
        }

        tr:last-child {
          display: flex;
          flex-direction: row;
          width: 100%;

          td:first-child {
            display: flex;
            width: 300px;
            margin-right: 65px;
            align-items: center;
            margin-top: 10px;
            justify-content: flex-end;
          }

          //td {
          //  display: flex;
          //  margin-right: 33px;
          //}
          //
          //td:nth-child(2) {
          //  width: 100px !important;
          //  margin-right: 26px !important;
          //}
        }

        .filter {
          display: flex;
          flex-direction: row;
        }
      }
    }

    .saveSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 35px;
      .editor {
        display: flex;
        flex-direction: column;
        .editorLegend {
          color: #868686;
        }
      }
      .btn {
        background-color: #90A4AE !important;
        margin-right: 20px;
        margin-left: 20px;
        height: 30px;
        position: initial;

        .btnText {
          margin-right: 10px;
          font-size: 13px !important;
          text-transform: initial !important;
        }

        .icon {
          font-size: 18px !important;
        }
      }
    }
  }
}
.deleteBtn {
  background-color: #DEA0A8 !important;
  height: 30px;

  .deleteBtnText {
    margin-right: 10px;
    font-size: 13px !important;
    text-transform: initial !important;
  }

  .icon {
    font-size: 18px !important;
  }
}
.required {
  color: #FF0000;
}

.requiredField {
  color: #FF0000;
  margin-left: 5px;
  margin-top: 10px;
}

.closeIcon {
  color: #FF0000;
  margin-top: 13px;
  cursor: pointer;
}

.closeProductIcon {
  color: #FF0000;
  cursor: pointer;
}

.dotsBtn {
  display: flex;
  margin-top: 13px;
  position: relative;
  left: -30px;
  cursor: pointer;
}

.helper {
  color: #90A4AE;
  margin-top: 2.5px;
}
