.wrapper {
  display: flex;
  align-self: center;
  margin: 0 20px;
}
.btn {
  color: #FFFFFF !important;
  text-transform: none !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
}
.btn:hover {
  cursor: pointer !important;
  background-color: #4D5C61 !important;
  border-radius: 5px !important;
}
.navlink {
  color: #ffffff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  white-space: nowrap !important;
  text-transform: none !important;
}
.isActive {
  display: flex;
  color: #ffffff !important;
  text-decoration: none !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
  text-transform: none !important;
  padding: 8px !important;
  border: 1px solid #90A4AE !important;
  border-radius: 5px !important;
  background-color: #4D5C61 !important;
  .label {
    white-space: nowrap !important;
    filter: drop-shadow(0px 4px 4px #000000);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
}
.menu {
  background-color: #535353;
}
