.wrapper {
  padding: 70px 30px;
  background-color: #ECEFF1;
  .closeGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .dicon {
    font-size: 24px !important;
    margin-left: 10px;
    color: #CCCCCC;
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    .productGroup {
      display: flex;
      flex-direction: row;
      justify-content: left;
      .productsTitle {
        font-size: 18px !important;
        color: #535353;
        font-weight: 700;
        margin-right: 20px;
      }
    }
    .form {
      display: flex;
      background-color: #ECEFF1 !important;
      flex-direction: column;
      padding: 10px 30px 30px 30px;
      .listener {
        display: flex;
        justify-content: center;
      }
      .error {
        color: #FF0000;
        display: flex;
        height: 21px;
        margin-left: 15px;
      }
      .header {
        margin-left: 15px;
        display: flex;
        justify-content: space-between;
        .searchSection {
          display: flex;
          .searchGroup {
            display: flex;
            flex-direction: column;
            .hint {
              color: #535353;
              margin-top: 5px;
            }
          }
          .btn {
            background-color: #90A4AE !important;
            height: 30px;
            position: initial;
            align-items: center;
            width: 110px;
            .btnText {
              margin-right: 10px;
              align-self: center;
              font-size: 14px !important;
              text-transform: initial !important;
            }
            .icon {
              font-size: 18px !important;
            }
          }
          .btn:nth-child(2) {
            margin-right: 20px;
            margin-left: 20px;
            position: initial;
          }
        }
      }

      .productData {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        .productsDataTitle {
          font-size: 16px !important;
          color: #535353;
          font-weight: 700;
        }
        .mainBlock {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          .leftBlock {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 50px;
             .leftBlockDisabled {
               background-color: #eceff1!important;
             }

            .error {
              color: #FF0000;
              display: flex;
              height: 21px !important;
              margin-top: 15px;
              margin-left: 0 !important;
            }
          }

          .rightBlock {
            display: flex;
            align-self: flex-start;

            .preview {
              width: 300px;
              height: 150px;
              background-color: #D9D9D9;
            }

            .img {
              cursor: pointer;
              margin-left: 15px;
            }

            .iconButton {
              width: 24px;
              height: 24px;
              margin-left: 15px;
            }
          }
        }
      }
      .divider {
        border-color: rgb(9, 9, 9) !important;
      }
      .packingConfig {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 15px;
        margin-bottom: 50px;
        width: fit-content;
        .configTitle {
          font-size: 16px !important;
          color: #535353;
          font-weight: 700;
          margin-bottom: 5px;
        }
        .packingTable {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;
          margin-right: 10px;
          .firstColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 25px;
            .title {
              white-space: nowrap;
            }
          }
          .secondColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 30px;
            .title {
              white-space: nowrap;
            }
          }
          .thirdColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 30px;
            .title {
              white-space: nowrap;
            }
          }
          .fourthColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 30px;
            .title {
              white-space: nowrap;
            }
          }
          .fifthColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 30px;
            .title {
              white-space: nowrap;
            }
          }
          .sixthColumn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            .title {
              white-space: nowrap;
            }
          }
        }
      }

      .templatesConfig {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-left: 15px;
        margin-bottom: 50px;
        .templatesConfigTitle {
          font-size: 16px !important;
          color: #535353;
          font-weight: 700;
          margin-bottom: 5px;
        }
        .templatesConfigTable {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 20px;
          margin-right: 10px;
          width: fit-content;
          .firstColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 25px;
          }
          .secondColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 30px;
          }
          .thirdColumn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}

.editorLegend {
  margin-top: 10px;
}

.editorLegendText {
  color: #777777;
}

.icon {
  font-size: 24px !important;
  margin-left: 10px;
  color: #333333;
  cursor: pointer;
}
.photoIcon {
  color: #1D8145;
  height: 30px !important;
  font-size: 20px !important;
  margin-top: 10px !important;
  cursor: pointer;
}
.noPhotoIcon {
  color: #535353;
  height: 30px !important;
  font-size: 20px !important;
  margin-top: 10px !important;
  cursor: pointer;
}
