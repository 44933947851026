.wrapper {
  padding: 35px 30px 15px 30px;
  height: 100%;
  background-color: #ECEFF1;
  display: flex;
  flex-direction: column;

  .loadingGroup {
    display: flex;
    flex-direction: row;
    .packagingSubTitle {
      font-size: 16px !important;
      color: #535353;
      font-weight: 700;
      margin-bottom: 5px;
      margin-right: 20px;
    }
  }

  .header {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .searchSection {
      display: flex;
      .searchGroup {
        display: flex;
        flex-direction: column;
        .hint {
          color: #535353;
          margin-top: 5px;
        }
      }
      .btn {
        background-color: #90A4AE !important;
        height: 30px;
        position: initial;
        display: flex;
        align-self: self-end;
        .btnText {
          margin-right: 10px;
          font-size: 13px !important;
          text-transform: initial !important;
        }
        .icon {
          font-size: 18px !important;
        }
      }
      .btn:nth-child(2) {
        margin-right: 20px;
        position: initial;
        font-size: 13px !important;
        text-transform: initial !important;
      }
    }
  }
}

.icon {
  font-size: 16px !important;
}

@media (min-width: 1800px) {
  .icon {
    font-size: 20px !important;
  }
}
