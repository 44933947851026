body {
  margin: 0;
  background-color: #ECEFF1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
}
/*@media (max-width: 1230px) {*/
/*  * {*/
/*    font-family: Roboto, sans-serif;*/
/*    font-size: 11px !important;*/
/*  }*/
/*}*/

/*@media (min-width: 1230px) and (max-width: 1800px){*/
/*  * {*/
/*    font-family: Roboto, sans-serif;*/
/*    font-size: 13px !important;*/
/*  }*/
/*}*/

/*@media (min-width: 1800px) {*/
/*  * {*/
/*    font-family: Roboto, sans-serif;*/
/*    font-size: 15px !important;*/
/*  }*/
/*}*/

* {
  font-family: Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
