.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  .title {
    font-size: 13px;
    font-weight: 400;
  }
  a {
    margin-bottom: 10px;
  }
  .btn {
    background-color: #90A4AE !important;
    height: 30px;
    margin-top: 10px;
    position: initial;
    align-items: center;
    width: 110px;
    .btnText {
      align-self: center;
      font-size: 13px !important;
      text-transform: initial !important;
    }
  }
}

