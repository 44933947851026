.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 10px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.iconViewer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  .title {
    margin-bottom: 8px;
    margin-top: 8px
  }
  .icon {
    font-size: 24px !important;
    color: #1D8145;
    margin-bottom: 5px
  }
  .icon:hover {
    cursor: pointer;
  }
}
.body {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  .title {
    margin-bottom: 10px;
  }
}
.icon {
  font-size: 24px !important;
  color: #000000;
  margin-left: 18px;
}
.icon:hover {
  cursor: pointer;
}
.btnSection {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  .deleteBtn {
    background-color: #DEA0A8 !important;
    margin-right: 20px;
    height: 30px;
    position: initial;

    .btnText {
      margin-right: 0px;
      color: #ffffff;
      text-transform: initial !important;
      line-height: 0;
      font-size: 13px !important;
    }

    .icon {
      font-size: 18px !important;
      color: #ffffff;
    }
  }
  .saveBtn {
    background-color: #90A4AE !important;
    height: 30px;
    position: initial;

    .btnText {
      margin-right: 0px;
      color: #ffffff;
      text-transform: initial !important;
      line-height: 0;
      font-size: 13px !important;
    }

    .icon {
      font-size: 18px !important;
      color: #ffffff;
    }
  }
  .saveBtn:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}
.required {
  color: red;
}
.errorMessage {
  color: red;
}

.editor {
  display: flex;
  flex-direction: column; 
  .editorLegend {
    color: #868686;
  }
}

.helper {
  color: #666666;
  margin-left: 24px;
}