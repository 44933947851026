.wrapper {
  display: flex;
  flex-direction: column;
  padding: 35px 30px;
  background-color: #ECEFF1;

  .title {
    font-size: 18px;
    color: #535353;
    font-weight: 700;
  }

  .fieldname {
    padding: 15px 0px 5px 0px;
  }

  .largeInputElement {
    display: flex;
    justify-content: space-between;
    width: 610px;
    align-items: center;
    margin-top: 10px;
    .titleInside {
      //white-space: nowrap;
      margin-right: 20px;
      line-height: 15px;
    }
  }

  .country {
    display: flex;
    justify-content: space-between;
    width: 570px;
    align-items: center;
    margin-top: 10px;
    .titlecountry {
      //white-space: nowrap;
      margin-right: 20px;
      line-height: 15px;
    }
    .btn {
      background-color: #90A4AE !important;
      height: 30px;
      display: flex;
      width: 120px;
      margin-left: 20px;
      .btnText {
        font-size: 13px !important;
        text-transform: initial !important;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    .passwordDiv {
      display: flex;
      justify-content: space-between;
      width: 825px;
      align-items: center;
      margin-top: 10px;
      .passwordSpan {
        margin-right: 20px;
        line-height: 15px;
        .required {
          color: #FF0000;
        }
      }
    }
  }

  .shippingCountry {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .kz {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .description {
      font-weight: 600;
    }
    .passwordDiv {
      display: flex;
      justify-content: space-between;
      width: 610px;
      align-items: center;
      margin-top: 10px;
      .passwordSpan {
        margin-right: 20px;
        line-height: 15px;
        .required {
          color: #FF0000;
        }
      }

      .socketGroup {
        display: flex;
        position: absolute;
        left: 650px;
        align-items: center;
        .test {
          background-color: #90A4AE !important;
          height: 30px;
          display: flex;
          width: 120px;
          margin-right: 10px;
          .btnText {
            font-size: 13px !important;
            text-transform: initial !important;
          }
        }
      }
    }
  }

  .uz {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .btnGroup {
    display: flex;
    .btn {
      background-color: #90A4AE !important;
      height: 30px;
      display: flex;
      width: 120px;
      margin-top: 40px;
      .btnText {
        font-size: 13px !important;
        text-transform: initial !important;
      }
    }
    .changes {
      display: flex;
      margin-left: 40px;
      align-items: self-end;
      margin-bottom: 5px;
    }
  }
}
